import React from 'react';
import { EditablePage } from '@magnolia/react-editor';

import Events from 'utils/Events';
import { getPage } from 'utils/magnolia';
import url from 'utils/url';

// Pages
import Basic from 'pages/Basic';
import Category from 'pages/Category';
import Home from 'pages/Home';
import Inspiration from 'pages/Inspiration';
import ManagedCampaignPage from 'pages/ManagedCampaign';
import Mobile from 'pages/Mobile';
import Search from 'pages/Search';
import Products from 'pages/Products';
import EventsPage from 'pages/EventsPage';
import ContactPage from 'pages/Contact';
import NewsPortal from 'pages/NewsPortal';

// Components
import Banner from 'components/Banner';
import Carousel from 'components/Carousel';
import Card from 'components/Card';
import Image from 'components/Image';
import Hotspot from 'components/Hotspot';
import Product from 'components/Product';
import RichText from 'components/RichText';
import Section from 'components/Section';
import Stories from 'components/Stories';
import Title from 'components/Title';
import Comment from 'components/Comment';
import CustomerJourney from 'components/CustomerJourney';
import Poll from 'components/Poll';
import FileDownload from 'components/FileDownload';
import MetaInfo from 'components/MetaInfo';
import Contact from 'components/Contact';
import Commenting from 'components/Commenting';
import FreshRelevance from 'components/FreshRelevance';
import EventTeaser from 'components/Event';
import StoryTeaser from 'components/Story';

// Campaign manager
import ManagedCampaign from 'components/ManagedCampaign';

const config = {
  componentMappings: {
    // Pages
    'demo-ecommerce:pages/Basic': Basic,
    'demo-ecommerce:pages/Category': Category,
    'demo-ecommerce:pages/Filter': Category,
    'demo-ecommerce:pages/Home': Home,
    'demo-ecommerce:pages/Inspiration': Inspiration,
    'demo-ecommerce:pages/ManagedCampaign': ManagedCampaignPage,
    'demo-ecommerce:pages/CustomerJourney': ManagedCampaignPage,
    'feature-mobile:pages/Mobile': Mobile,
    'demo-ecommerce:pages/Search': Search,
    'demo-ecommerce:pages/Products': Products,
    'demo-ecommerce:pages/Events': EventsPage,
    'demo-ecommerce:pages/Contact': ContactPage,
    'demo-ecommerce:pages/NewsPortal': NewsPortal,

    // Components
    'demo-ecommerce:components/Banner': Banner,
    'demo-ecommerce:components/Carousel': Carousel,
    'demo-ecommerce:components/Card': Card,
    'demo-ecommerce:components/Image': Image,
    'demo-ecommerce:components/Hotspot': Hotspot,
    'demo-ecommerce:components/Product': Product,
    'demo-ecommerce:components/RichText': RichText,
    'demo-ecommerce:components/Section': Section,
    'demo-ecommerce:components/Stories': Stories,
    'demo-ecommerce:components/Title': Title,
    'demo-ecommerce:components/Comment': Comment,
    'demo-ecommerce:components/Poll': Poll,
    'demo-ecommerce:components/FileDownload': FileDownload,
    'demo-ecommerce:components/MetaInfo': MetaInfo,
    'demo-ecommerce:components/Contact': Contact,
    'demo-ecommerce:components/Commenting': Commenting,
    'demo-ecommerce:components/FreshRelevance': FreshRelevance,
    'demo-ecommerce:components/EventTeaser': EventTeaser,
    'demo-ecommerce:components/StoryTeaser': StoryTeaser,

    // Campaign manager
    'campaign-manager:components/managed-campaign': ManagedCampaign,

    // CustomerJourney
    'customer-journey-core:components/customer-journey': CustomerJourney,
  },
};

class PageLoader extends React.Component {
  state = {};

  loadPage = async () => {
    const pathname = window.location.pathname;

    if (this.state.pathname === pathname) return;

    // Fetch page content and if in Magnolia fetch Magnolia specifc data (template annotations)
    const page = await getPage();

    const robots = page.content.noIndex === true ? 'noindex, nofollow' : 'index, follow';
    const link = url.getCanonicalHref(page.content.canonical);
    const title = page.content.title;
    const description = page.content.description || title;
    const keywords = page.content.keywords || title;
    const image = page.content.image?.['@link'] || '#';

    Events.emit('updateTitle', title);

    document.getElementsByTagName('meta')['robots'].content = robots;
    document.querySelector('link[rel="canonical"]').href = link;
    document.getElementsByTagName('meta')['description'].content = description;
    document.getElementsByTagName('meta')['keywords'].content = keywords;
    document.querySelector('meta[property="og:title"]').content = title;
    document.querySelector('meta[property="og:description"]').content = description;
    document.querySelector('meta[property="og:image"]').content = image;

    this.setState({ page, pathname });
  };

  componentDidMount() {
    this.loadPage();
  }

  componentDidUpdate() {
    this.loadPage();
  }

  render() {
    const { page } = this.state;

    return page ? (
      <EditablePage templateAnnotations={page.templateAnnotations} content={page.content} config={config} />
    ) : null;
  }
}

export default PageLoader;
