import React, { useState, useEffect, useRef } from 'react';
import Settings from 'utils/Settings';

function isNotEmptyObject(object) {
  return object && Object.keys(object).length > 0;
}

function getImageSrc(image, method, width, height) {
  const baseUrl = Settings.getItem('baseUrl');

  let imageLink = '#';

  if (!image) return imageLink;

  if (typeof image === 'string') {
    imageLink = baseUrl + '/dam/' + image;
  } else {
    const focal = image.focal;

    if (isNotEmptyObject(focal)) {
      if (method === 'areas') {
        imageLink = focal.areas?.auto?.url?.replace('_WIDTH_', width).replace('_HEIGHT_', height);
      } else {
        imageLink = focal.point?.urls?.auto?.replace('_WIDTH_', width).replace('_HEIGHT_', height);
      }

      if (!imageLink) {
        imageLink = '#';
      }
    } else {
      imageLink = image['@link'];
    }

    imageLink = imageLink.indexOf('http') > -1 ? imageLink : baseUrl + imageLink.replace(/^.*?\/\.imaging/, "/.imaging");
  }

  return imageLink;
}

function BackgroundImage(props) {
  const { className, method = 'point', image, children } = props;
  const [imageSrc, setImageSrc] = useState();
  const eleRef = useRef(null);
  let newClassName = 'BackgroundImage cover';
  let newStyle = {};
  let newProps = {};

  Object.keys(props).forEach((key) => {
    if (!['className', 'method', 'image'].includes(key)) newProps[key] = props[key];
  });

  useEffect(() => {
    const newImageSrc = getImageSrc(image, method, eleRef.current.offsetWidth, eleRef.current.offsetHeight);

    setImageSrc(newImageSrc);
  }, [image, method]);

  if (className) newClassName += ' ' + className;
  if (imageSrc) newStyle.backgroundImage = 'url(' + imageSrc + ')';
  newStyle.backgroundPosition = `${image?.focal?.point?.left}% ${image?.focal?.point?.top}%`

  return (
    <div className={newClassName} ref={eleRef} style={newStyle} {...newProps}>
      {!imageSrc && <div className='BackgroundImage__spinner' />}
      {children}
    </div>
  );
}

export default BackgroundImage;
